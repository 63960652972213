import { useTranslation } from "react-i18next";

const FILL_COLOR = "#23a47f";

interface Props {
  height: number;
  width: number;
  color?: string;
}

export const GreenhouseLogo = ({ height, width, color }: Props) => {
  const { t } = useTranslation("common");
  const fillColor = color || FILL_COLOR;

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 530.8 119.01"
      xmlSpace="preserve"
      enableBackground={"new 0 0 530.8 119.01"}
      height={height}
      width={width}
      role="img"
      aria-label={t("labels.greenhouse_logo") || ""}
    >
      <text style={{ visibility: "hidden" }} fontSize={0}>
        Greenhouse
      </text>
      <g>
        <path
          fill={fillColor}
          d="M44.9,43.3c0,5.2-2.2,9.8-5.8,13.4c-4,4-9.8,5-9.8,8.4c0,4.6,7.4,3.2,14.5,10.3c4.7,4.7,7.6,10.9,7.6,18.1
		c0,14.2-11.4,25.5-25.7,25.5S0,107.71,0,93.51c0-7.2,2.9-13.4,7.6-18.1c7.1-7.1,14.5-5.7,14.5-10.3c0-3.4-5.8-4.4-9.8-8.4
		c-3.6-3.6-5.8-8.2-5.8-13.6c0-10.4,8.5-18.8,18.9-18.8c2,0,3.8,0.3,5.3,0.3c2.7,0,4.1-1.2,4.1-3.1c0-1.1-0.5-2.5-0.5-4
		c0-3.4,2.9-6.2,6.4-6.2c3.5,0,6.3,2.9,6.3,6.4c0,3.7-2.9,5.4-5.1,6.2c-1.8,0.6-3.2,1.4-3.2,3.2C38.7,30.5,44.9,33.8,44.9,43.3z
		 M42.9,93.51c0-9.9-7.3-17.9-17.2-17.9c-9.9,0-17.2,8-17.2,17.9c0,9.8,7.3,17.9,17.2,17.9C35.6,111.41,42.9,103.3,42.9,93.51z
		 M37,43.1c0-6.3-5.1-11.5-11.3-11.5s-11.3,5.2-11.3,11.5s5.1,11.5,11.3,11.5S37,49.4,37,43.1z"
        />
        <path
          fill={fillColor}
          d="M80.9,25.3c3.2,0,5.6,2.4,5.6,5.4s-2.3,5.4-5.5,5.4c-3.3,0-5.1-2.1-8.4-2.1c-3.2,0-5.7,2.3-5.7,6.2v31.5
		c0,2.4-1.9,4.3-4.3,4.3c-2.3,0-4.2-1.9-4.2-4.3V29.3c0-2.4,1.9-4.3,4.2-4.3c4.3,0,3.5,4.1,7.8,4.1C74.5,29.1,76,25.3,80.9,25.3z"
        />
        <path
          fill={fillColor}
          d="M90.2,50.5c0-15.1,10.4-26,24.7-26c13.1,0,23.5,9.6,23.5,22.7c0,3.1-2,5.2-4.9,5.2H101c-1.4,0-1.9,0.5-1.9,1.6
		c0,2.5,2,7.1,5,10.1s7.1,4.8,12.9,4.8c4.3,0,8.1-1.6,11-3.7c0.9-0.7,1.9-1.1,2.9-1.1c2.1,0,3.8,1.9,3.8,3.9c0,1.2-0.4,2.2-1.4,3.1
		c-4.2,3.5-10.1,5.4-16.5,5.4C101.3,76.5,90.2,65.3,90.2,50.5z M125.6,36.1c-2.8-2.8-6.4-4.2-10.7-4.2c-4.3,0-8.3,1.7-11.2,4.6
		c-2.3,2.3-3.9,5.3-3.9,7.3c0,1,0.6,1.4,1.9,1.4h26c1.2,0,1.8-0.4,1.8-1.4C129.5,41.4,127.6,38.1,125.6,36.1z"
        />
        <path
          fill={fillColor}
          d="M147,50.5c0-15.1,10.4-26,24.7-26c13.1,0,23.5,9.6,23.5,22.7c0,3.1-2,5.2-4.9,5.2h-32.5
		c-1.4,0-1.9,0.5-1.9,1.6c0,2.5,2,7.1,5,10.1s7.1,4.8,12.9,4.8c4.3,0,8.1-1.6,11-3.7c0.9-0.7,1.9-1.1,2.9-1.1c2.1,0,3.8,1.9,3.8,3.9
		c0,1.2-0.4,2.2-1.4,3.1c-4.2,3.5-10.1,5.4-16.5,5.4C158.1,76.5,147,65.3,147,50.5z M182.4,36.1c-2.8-2.8-6.4-4.2-10.7-4.2
		c-4.3,0-8.3,1.7-11.2,4.6c-2.3,2.3-3.9,5.3-3.9,7.3c0,1,0.6,1.4,1.9,1.4h26c1.2,0,1.8-0.4,1.8-1.4
		C186.3,41.4,184.4,38.1,182.4,36.1z"
        />
        <path
          fill={fillColor}
          d="M245.3,76c-2.4,0-4.3-1.9-4.3-4.3V44.5c0-7.1-5.6-12.5-12.8-12.5c-7.3,0-12.9,5.5-12.9,12.5v27.2
		c0,2.4-1.9,4.3-4.3,4.3c-2.3,0-4.2-1.9-4.2-4.3V29.2c0-2.3,1.9-4.2,4.1-4.2c4.2,0,4,3.8,6.2,3.8c2.2,0,5.2-4.3,13-4.3
		c10.9,0,19.4,9.1,19.4,20v27.2C249.5,74.1,247.6,76,245.3,76z"
        />
        <path
          fill={fillColor}
          d="M302.7,76c-2.4,0-4.3-1.9-4.3-4.3V44.5c0-7.1-5.6-12.5-12.8-12.5c-7.3,0-12.9,5.5-12.9,12.5v27.2
		c0,2.4-1.9,4.3-4.3,4.3c-2.3,0-4.2-1.9-4.2-4.3V4.3c0-2.4,1.9-4.3,4.2-4.3c2.4,0,4.3,1.9,4.3,4.3v22.1c0,1.2,0.5,1.9,1.8,1.9
		c2.2,0,5.2-3.8,13-3.8c10.9,0,19.4,9.1,19.4,20v27.2C306.9,74.1,305,76,302.7,76z"
        />
        <path
          fill={fillColor}
          d="M318.59,50.5c0-14.5,11.6-26,26.2-26c14.6,0,26.2,11.5,26.2,26c0,14.5-11.6,26-26.2,26
		C330.2,76.5,318.59,65,318.59,50.5z M362.5,50.5c0-10.1-7.5-18.4-17.7-18.4c-10.1,0-17.6,8.3-17.6,18.4c0,10.1,7.5,18.4,17.6,18.4
		C355,68.9,362.5,60.6,362.5,50.5z"
        />
        <path
          fill={fillColor}
          d="M386.89,25c2.4,0,4.3,1.9,4.3,4.3v27.2c0,7.1,5.6,12.5,12.8,12.5c7.3,0,12.9-5.5,12.9-12.5V29.3
		c0-2.4,1.9-4.3,4.3-4.3c2.3,0,4.2,1.9,4.2,4.3v42.5c0,2.3-1.9,4.2-4.1,4.2c-4.2,0-4-3.8-6.2-3.8c-2.2,0-5.2,4.3-13,4.3
		c-10.9,0-19.4-9.1-19.4-20V29.3C382.7,26.9,384.59,25,386.89,25z"
        />
        <path
          fill={fillColor}
          d="M440.49,72.8c-1.9-1.2-3.2-2.4-3.2-4.7c0-2.2,1.7-3.9,3.8-3.9c2.5,0,3.6,1.5,5.7,2.6c2.6,1.5,5.6,2.3,9.3,2.3
		c6.8,0,10.2-3.1,10.2-6.9c0-5.4-5-6.4-11.8-8.2c-8.4-2.2-16.5-4.7-16.5-14.4c0-8.6,6.6-15.1,18.3-15.1c4.6,0,8.9,1.2,12.3,3.3
		c1.2,0.7,2.4,2.2,2.4,3.9c0,2.2-1.8,4.1-4.1,4.1c-1.5,0-2.7-1-4.2-2c-1.5-1-3.7-1.9-6.7-1.9c-6.7,0-9.6,2.9-9.6,6.8
		c0,5.1,4.9,6.3,11.9,8c8.2,2,16.3,4.6,16.3,14.5c0,8.6-6.4,15.3-18.7,15.3C449.89,76.5,444.49,75.3,440.49,72.8z"
        />
        <path
          fill={fillColor}
          d="M482.59,50.5c0-15.1,10.4-26,24.7-26c13.1,0,23.5,9.6,23.5,22.7c0,3.1-2,5.2-4.9,5.2h-32.5
		c-1.4,0-1.9,0.5-1.9,1.6c0,2.5,2,7.1,5,10.1s7.1,4.8,12.9,4.8c4.3,0,8.1-1.6,11-3.7c0.9-0.7,1.9-1.1,2.9-1.1c2.1,0,3.8,1.9,3.8,3.9
		c0,1.2-0.4,2.2-1.4,3.1c-4.2,3.5-10.1,5.4-16.5,5.4C493.7,76.5,482.59,65.3,482.59,50.5z M518,36.1c-2.8-2.8-6.4-4.2-10.7-4.2
		c-4.3,0-8.3,1.7-11.2,4.6c-2.3,2.3-3.9,5.3-3.9,7.3c0,1,0.6,1.4,1.9,1.4h26c1.2,0,1.8-0.4,1.8-1.4C521.9,41.4,520,38.1,518,36.1z"
        />
      </g>
    </svg>
  );
};
